import React from "react"
import Section from "../../library/section/section"

function Footer() {
  return (
    <Section bgColor={"#f5f5f5"}>
      <footer>
        <h3>FiveCreature Design, LLC</h3>
        <br />© {new Date().getFullYear()}, Built by FiveCreature Designs
      </footer>
    </Section>
  )
}
export default Footer
