import React, { useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import headerStyles from "./header.module.scss"

function Header(props) {
  const { siteTitle } = props
  return (
    <header className={headerStyles.header}>
      <Link to="/" className={headerStyles.header_link}>
        <h1> {siteTitle}</h1>
      </Link>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
