import React from "react"

import sectionStyles from "./section.module.scss"

function Section(props) {
  const { children, bgColor } = props

  return (
    <section
      style={{
        backgroundColor: bgColor,
      }}
      className={sectionStyles.container}
    >
      <div className={sectionStyles.content}>{children}</div>
    </section>
  )
}

export default Section
